import React from "react"
import Layout from "../components/layouts/investments_layout"
import Meta from "../components/meta"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import DogImg from "../images/lili-flip.jpg"
import { FaBone } from "react-icons/fa"

const Contact = () => (
  <Layout>
    <Meta title="Contact threesixty Investments Ltd" description="Get in touch with threesixty Investments" />
    <Container>
      <Row className="mb-4">
        <Col>
          <h1>Get in touch &nbsp;<FaBone color="#ff5700" size={64} /></h1>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={6}>
          <form action="/thanks/" name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
            <input id="formName" type="hidden" aria-hidden="true" aria-label="formName" name="form-name" value="contact" />
            <div className="mb-3">
              <label id="emailLabel" htmlFor="email" className="form-label">
                Email address
              </label>
              <input type="email" className="form-control" id="email" name="email" aria-labelledby="emailLabel" placeholder="" />
            </div>
            <p hidden>
              <label id="botLabel" htmlFor="bot-field" className="form-label">
                Don't fill this out:
              </label>
              <input name="bot-field" id="bot-field" aria-labelledby="botLabel" />
            </p>
            <div className="mb-3">
              <label id="nameLabel" htmlFor="name" className="form-label">
                Name
              </label>
              <input type="string" className="form-control" id="name" name="name" aria-labelledby="nameLabel" placeholder="" />
            </div>
            <div className="mb-3">
              <label id="phoneLabel" htmlFor="phone" className="form-label">
                Phone Number
              </label>
              <input type="tel" className="form-control" id="phone" name="phone" aria-labelledby="phoneLabel" placeholder="" />
            </div>
            <div className="mb-3">
              <label id="subjectLabel" htmlFor="subject" className="form-label">
                Subject
              </label>
              <input type="string" className="form-control" id="subject" name="subject" aria-labelledby="subjectLabel" />
            </div>
            <div className="mb-3">
              <label id="messageLabel" htmlFor="message" className="form-label">
                Message
              </label>
              <textarea className="form-control" id="message" name="message" rows="7" aria-labelledby="messageLabel"></textarea>
            </div>
            <button type="submit" className="btn btn-investments-light-accent text-investments-light">Submit</button>
          </form>
        </Col>
        <Col lg={6}>
          <img src={DogImg} className="mt-4 imageCard" height="580" alt="Lili the dog is waiting patiently for your message." />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default Contact;