import React from "react"


export const Meta = (props) => {
    return (
        <>
            <title>{props.title}</title>
            <meta name="format-detecetion" content="telephone=no" />
            <meta name="description" content={props.description} />
        </>
    )
}

Meta.defaultProps = {
    title: "threesixty Investments Ltd",
    description: "threesixty Investments Ltd take a long term approach to creating value and growing talent within our investments."
}

export default Meta
